import React from 'react';

import Loading from '../../../pages/Loading';
import { ApplicationContextPublic } from '../../../contexts/ApplicationContextPublic';
import VulaLogo from '../../utils/VulaLogo';
import PublicApplicationForm from './PublicApplicationForm';

export default function PublicApplication() {
  const { formDefinition, publicFormIsLoading } = React.useContext(
    ApplicationContextPublic,
  );

  if (publicFormIsLoading) {
    return <Loading />;
  }

  if (!formDefinition) {
    // todo: turn into component to send us an email if it happens
    return (
      <div className="w-full bg-white">
        <div className="rotate-[175deg] ">
          <VulaLogo />
        </div>
        <p className="text-2xl font-bold py-4">Something went wrong!</p>
      </div>
    );
  }

  return (
    <div
      className="min-h-[100vh] min-w-[100vw] h-full w-full"
      style={{ backgroundColor: formDefinition.bg_color }}
    >
      <div className="max-w-[1000px] m-auto p-1  flex flex-col items-center justify-center h-full">
        {/* SECTION 1 */}
        <PublicApplicationForm />
      </div>
    </div>
  );
}
