import React, { createContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { VulaUserAPI } from '../api/user';
import { apiUrl } from '../methods/env';
import { VulaPitchAPI } from '../api/pitch';
import { timeoutWrapper } from '../methods/UIHelpers';
import { routes } from '../pages/routes';

// type of state
interface LoggedInContextProps {
  isAuthenticated: boolean;
  company_id: string;
  setCompanyId: (company_id: string) => void;
  company_slug: string;
  setCompanySlug: (company_slug: string) => void;
  company_name: string;
  setCompanyName: (company_name: string) => void;
  company_logo: string;
  setCompanyLogo: (company_logo: string) => void;
  company_elevator_pitch: string;
  setCompanyElevatorPitch: (company_logo: string) => void;
  getUserData: () => void;
  onboardingProgress: string;
  setOnboardingProgress: (status: string) => void;
  isLoading: boolean;
  referrer: string;
}

// Initialise with Context engine
export const LoggedInContext = createContext<LoggedInContextProps>({
  isAuthenticated: false,
  company_id: '',
  setCompanyId: () => undefined,
  company_slug: '',
  setCompanySlug: () => undefined,
  company_name: '',
  setCompanyName: () => undefined,
  company_logo: '',
  setCompanyLogo: () => undefined,
  company_elevator_pitch: '',
  setCompanyElevatorPitch: () => undefined,
  getUserData: () => undefined,
  onboardingProgress: 'notStarted',
  setOnboardingProgress: () => undefined,
  isLoading: true,
  referrer: '',
});

const LoggedInProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    getAccessTokenSilently,
    isAuthenticated,
    loginWithRedirect,
    isLoading: auth0IsLoading,
  } = useAuth0();
  // init state
  const [company_id, setCompanyId] = useState('');
  const [company_slug, setCompanySlug] = useState('');
  const [company_name, setCompanyName] = useState('');
  const [company_logo, setCompanyLogo] = useState('');

  const [referrer, setReferrer] = useState('');
  const [onboardingProgress, setOnboardingProgress] = useState('');
  // only used in onboarding
  const [company_elevator_pitch, setCompanyElevatorPitch] = useState('');
  // is loading state
  const [isLoading, setIsLoading] = useState(auth0IsLoading);

  // onload get the user data
  // we seem to loose this, so on each page change load user to be safer
  useEffect(() => {
    // if auth0 is loading then just wait
    if (!auth0IsLoading) {
      // if not authed, auth (unless on public page)
      if (!isAuthenticated) {
        if (Object.values(routes.public).includes(window.location.pathname)) {
          // no action needed but keeping for logging help - delete later
          return;
        } else {
          // check that the route isn't like a wild card route (e.g. /pitch/*)
          const wildcardRoutes = Object.values(routes.public).map(route => {
            if (route.includes('/*')) {
              const trimmedRoute = route.replace('/*', '');
              return trimmedRoute;
            }
            return '';
          });

          const isWildcardRoute = Object.values(wildcardRoutes).some(route =>
            window.location.pathname.includes(route),
          );

          if (isWildcardRoute) {
            return;
          }

          loginWithRedirect();
        }
      } else {
        (async () => {
          await getUserData();
        })();
      }
    }
  }, [window.location.pathname, auth0IsLoading]);

  useEffect(() => {
    (async () => {
      await getUserData();
    })();
  }, [company_slug, company_name]);

  const getUserData = async () => {
    setIsLoading(true);

    try {
      let auth0Token = '';

      await timeoutWrapper(
        async () =>
          await (auth0Token = await getAccessTokenSilently({
            audience: apiUrl,
          })),
        1500,
        undefined,
      );

      if (auth0Token) {
        const api = new VulaUserAPI({ token: auth0Token });

        const existingUserResponse = await api.getUserMe();
        const company_info =
          existingUserResponse.data.CompanyAdmins[0]?.Company;

        if (company_info) {
          setCompanyId(company_info.id);
          setCompanySlug(company_info.company_slug);
          setCompanyName(company_info.company_full_name);
          if (existingUserResponse.data.logo) {
            setCompanyLogo(existingUserResponse.data.logo);
          }
          setReferrer(existingUserResponse.data.referrer);

          // if they have a password we assume they have completed onboarding
          if (company_info._count.passphrases > 0) {
            setOnboardingProgress('completed');
          } else {
            const pitchApi = new VulaPitchAPI({ token: auth0Token });
            // get the elevator pitch to display
            // note: in hindsight maybe we need an onboarding context?
            try {
              const elevator_pitch = await pitchApi.getElevatorPitch(
                company_info.company_slug,
              );
              setCompanyElevatorPitch(elevator_pitch.data.answer);
            } catch (error) {
              console.log(error);
            }
          }
        } else {
          // no company info was found so must be a new user
        }
      }
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);
  };

  return (
    <LoggedInContext.Provider
      value={{
        isAuthenticated,
        company_id,
        setCompanyId,
        company_slug,
        setCompanySlug,
        company_name,
        setCompanyName,
        company_logo,
        setCompanyLogo,
        company_elevator_pitch,
        setCompanyElevatorPitch,
        getUserData,
        onboardingProgress,
        setOnboardingProgress,
        isLoading,
        referrer,
      }}
    >
      {children}
    </LoggedInContext.Provider>
  );
};

export default LoggedInProvider;
