import React from 'react';
import { ApplicationContextPublic } from '../../../contexts/ApplicationContextPublic';
import { useNavigate } from 'react-router-dom';

export default function AlreadyHaveAVulaAccount() {
  const navigate = useNavigate();
  const { formDefinition } = React.useContext(ApplicationContextPublic);

  if (!formDefinition || !formDefinition.apply_with_vula_url) {
    return null;
  }

  return (
    <>
      <button
        className="w-full text-[11px] sm:text-xs text-stone-300 hover:text-stone-600 text-center mt-2 decoration-transparent hover:decoration-stone-600 hover:underline transition-all duration-300"
        onClick={() => {
          navigate(formDefinition.apply_with_vula_url);
        }}
      >
        Have a Vula account already? Click here
      </button>
    </>
  );
}
