import React, { useState, useEffect } from 'react';
import HomeNavBar from '../components/Home/HomeNavBar';
import InProgressCard from '../components/Home/InProgressCard';
import { useNavigate } from 'react-router-dom';
import { routes } from './routes';
import { capitalizeFirstLetter } from '../methods/UIHelpers';
import { useAuth0 } from '@auth0/auth0-react';
import { VulaLendingAPI } from '../api/lending';
import { LoggedInContext } from '../contexts/LoggedInContext';
import { track } from '../interfaces/mixpanel';
import { EditPitchContext } from '../contexts/EditPitchContext';
import Button from '../components/utils/Button';
import { VulaCorpusAPI } from '../api/corpus';
import { sendNotification } from '../api/monitoring';
import Loading from './Loading';
import ApproximateFundingRequirement from '../components/Applications/ApproximateFundingRequirement';
import EstimatedAnnualRevenue from '../components/Applications/EstimatedAnnualRevenue';

interface Currency {
  code: string;
  symbol: string;
  name: string;
}

const currencies: Currency[] = [
  { code: 'USD', symbol: '$', name: 'US Dollar' },
  { code: 'ZAR', symbol: 'R', name: 'South African Rand' },
  { code: 'NGN', symbol: '₦', name: 'Nigerian Naira' },
  { code: 'KES', symbol: 'KSh', name: 'Kenyan Shilling' },
  { code: 'EGP', symbol: 'E£', name: 'Egyptian Pound' },
  { code: 'GHS', symbol: 'GH₵', name: 'Ghanaian Cedi' },
  { code: 'MAD', symbol: 'MAD', name: 'Moroccan Dirham' },
  { code: 'EUR', symbol: '€', name: 'Euro' },
  { code: 'GBP', symbol: '£', name: 'British Pound' },
  // Add more African currencies as needed
];

export default function Applications() {
  const navigate = useNavigate();
  const { getAccessTokenSilently, isLoading } = useAuth0();
  const { updateCorpus } = React.useContext(EditPitchContext);
  const { company_slug } = React.useContext(LoggedInContext);

  const [applications, setApplications] = useState<{
    [key: string]: {
      Partner: { name: string; logo: string; url: string };
      product_name: string;
      id: string;
      product_code: true;
      product_description: true;
      image: string;
      status: string;
    }[];
  }>({});
  const [estimatedAnnualRevenue, setEstimatedAnnualRevenue] = useState('');
  const [desiredLoanAmount, setDesiredLoanAmount] = useState('');
  const [detailsSubmitted, setDetailsSubmitted] = useState(false);
  const [otherFundingInfo, setOtherFundingInfo] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState<Currency>(currencies[0]); // Default to USD

  useEffect(() => {
    (async () => {
      const token = await getAccessTokenSilently();
      const api = new VulaLendingAPI({ token });
      const products = await api.getLendingProducts(company_slug);
      setApplications(products.data);
      const corpusAPI = new VulaCorpusAPI({ token });
      const corpus = await corpusAPI.getFromCorpus({
        slug: company_slug,
        labels: [
          'estimated_annual_revenue',
          'desired_funding_amount',
          'other_funding_info',
        ],
      });
      // response will be an array of objects (label, answer, question)
      const corpusData = corpus.data;
      const estimated_annual_revenue = corpusData.find(
        (item: { label: string }) => item.label === 'estimated_annual_revenue',
      );
      const desired_funding_amount = corpusData.find(
        (item: { label: string }) => item.label === 'desired_funding_amount',
      );
      setEstimatedAnnualRevenue(estimated_annual_revenue?.answer || '');
      setDesiredLoanAmount(desired_funding_amount?.answer || '');
      setOtherFundingInfo(
        corpusData.find(
          (item: { label: string }) => item.label === 'other_funding_info',
        )?.answer || '',
      );
      if (estimated_annual_revenue?.answer && desired_funding_amount?.answer) {
        setDetailsSubmitted(true);
      }
    })();
  }, [isLoading]);

  if (isLoading) {
    return <Loading size="full-page" />;
  }

  
  const handleSubmit = () => {
    track('Lending Applications further info submitted', {
      company_slug,
      estimated_annual_revenue: estimatedAnnualRevenue,
      desired_funding_amount: desiredLoanAmount,
      currency: selectedCurrency.code,
    });
    setDetailsSubmitted(true);
    sendNotification(
      `${company_slug} has submitted further info for lending applications. ` +
      `${estimatedAnnualRevenue} is their estimated annual revenue and ` +
      `${desiredLoanAmount} is their desired loan amount in ${selectedCurrency.code}.`,
      'Lending Applications further info submitted'
    );
  };

  const currencySelector = () => (
    <div className="max-w-[500px] m-auto my-2 bg-stone-100 rounded drop-shadow px-4 py-2">
      <div className="text-sm sm:text-xl pb-2">Select Currency</div>
      <select
        className="w-full py-2 px-4 bg-gray-200 rounded-xl"
        value={selectedCurrency.code}
        onChange={(e) => {
          const newCurrency = currencies.find(c => c.code === e.target.value);
          if (newCurrency) setSelectedCurrency(newCurrency);
        }}
      >
        {currencies.map((currency) => (
          <option key={currency.code} value={currency.code}>
            {currency.name} ({currency.symbol})
          </option>
        ))}
      </select>
    </div>
  );

  if (!applications || Object.keys(applications).length === 0) {
    if (detailsSubmitted === false) {
      track('Lending Applications further info request', { company_slug });
      return (
        <>
          <HomeNavBar />
          <div className="max-w-[1200px] sm:w-[90%] m-auto px-2 sm:px-20 text-left">
            <div className="sm:py-4">
              <h1 className="text-xl sm:text-5xl font-extrabold py-8">
                Tell Vula more to get started
              </h1>
              <p className="text-sm sm:text-xl py-2">
                We find you funding options, but need a bit more info to get
                going. The more accurate this is, the better matches we can find.
              </p>

              {currencySelector()}
              <EstimatedAnnualRevenue
                initialValue={estimatedAnnualRevenue || ''}
                onSave={(value: string) => {
                  setEstimatedAnnualRevenue(value);
                  updateCorpus('estimated_annual_revenue', value);
                }}
                currency={selectedCurrency}
              />
              <ApproximateFundingRequirement
                initialValue={desiredLoanAmount || ''}
                onSave={(value: string) => {
                  setDesiredLoanAmount(value);
                  updateCorpus('desired_funding_amount', value);
                }}
                currency={selectedCurrency}
              />

              <div className="flex justify-center mt-6">
                <Button
                  isDisabled={!estimatedAnnualRevenue || !desiredLoanAmount}
                  text="Submit"
                  action={handleSubmit}
                />
              </div>
            </div>
          </div>
        </>
      );
    } else {
      // if present and they havent got applications say we are looking for options
      track('Lending Applications View', { company_slug });
      return (
        <>
          <HomeNavBar />
          <div className=" max-w-[1200px] sm:w-[90%] m-auto px-2 sm:px-20 text-left  ">
            <h1 className=" text-xl sm:text-3xl font-extrabold py-8">
              Vula is searching for you...
            </h1>
            <div className="flex flex-row  w-full items-center sm:py-4">
              <div className=" sm:py-4">
                <div>
                  <p className="text-sm sm:text-lg">
                    You will be notified when we have found good options for
                    you.
                    <br />
                    We are searching based on the information you have provided.
                  </p>
                </div>
                {/* allow people to edit the info they gave */}

                <div className="text-sm sm:text-m py-2">
                  If you need to, you can edit the information you provided:
                </div>

                <div className="w-fit">
                  {<EstimatedAnnualRevenue
                initialValue={estimatedAnnualRevenue || ''}
                onSave={(value: string) => {
                  setEstimatedAnnualRevenue(value);
                  updateCorpus('estimated_annual_revenue', value);
                }}
                currency={selectedCurrency}
              />}
                  {
                  <ApproximateFundingRequirement
                  initialValue={desiredLoanAmount || ''}
                  onSave={(value: string) => {
                    setDesiredLoanAmount(value);
                    updateCorpus('desired_funding_amount', value);
                  }}
                  currency={selectedCurrency}
                />}
                </div>

                <div className="text-sm sm:text-m py-2">
                  If there is any other relevant information you would like to
                  share, enter it here:
                </div>
                <div
                  id="other_info"
                  contentEditable={true}
                  suppressContentEditableWarning={true}
                  onBlur={event => {
                    updateCorpus('other_funding_info', event.target.innerHTML);
                  }}
                  className={'mb-4 min-h-[80px] p-2 bg-stone-100 rounded-xl '}
                  dangerouslySetInnerHTML={{
                    __html: otherFundingInfo || '',
                  }}
                ></div>
              </div>
              <div className="sm:w-1/2 flex justify-center items-center px-4">
                <img
                  src="/assets/raw.gif"
                  alt="Vula Logo"
                  className="w-[80%] rounded-full "
                />
              </div>
            </div>
          </div>
        </>
      );
    }
  }

  return (
    <>
      <HomeNavBar />
      <div className=" max-w-[1200px] sm:w-[90%] m-auto px-2 sm:px-20 text-left ">
        <div className="flex flex-row  w-full items-center sm:py-4">
          <h1 className=" text-xl sm:text-5xl font-extrabold py-4">
            Applications
          </h1>
        </div>
        {Object.keys(applications).map(applicationStatus => {
          return (
            <>
              <h3>{capitalizeFirstLetter(applicationStatus)}</h3>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                {applications[applicationStatus].map(application => (
                  <InProgressCard
                    key={application.Partner.name + application.product_name}
                    headline={application.Partner.name}
                    subtitle={application.product_name}
                    background={application.image}
                    onClick={() => {
                      if (application.status === 'new') {
                        navigate(
                          routes.private.applicationsHome +
                            application.Partner.name +
                            '/' +
                            application.product_code,
                        );
                      }
                    }}
                  />
                ))}
              </div>
            </>
          );
        })}
      </div>
    </>
  );
}
