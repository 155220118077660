import React, { useContext } from 'react';

import ShareIcon from '../Icons/ShareIcon';
import ButtonCard from '../Pitch/PitchHome/ButtonCard';
import { routes } from '../../pages/routes';
import { LoggedInContext } from '../../contexts/LoggedInContext';
import CopyIcon from '../Icons/CopyIcon';

export default function ShareToInvestorsCard() {
  const { company_slug } = useContext(LoggedInContext);

  const [copiedToClipboard, setCopiedToClipboard] = React.useState(false);

  //Hide copy to clip messaage after 3 seconds
  React.useEffect(() => {
    if (copiedToClipboard) {
      setTimeout(() => {
        setCopiedToClipboard(false);
      }, 3000);
    }
  }, [copiedToClipboard]);

  return (
    <ButtonCard
      context="memo"
      action={() => {
        // if on mobile use navigate share else use copy to clipboard
        if (navigator.share) {
          navigator.share({
            title: 'Share your memo',
            text: 'Share your memo with investors by sending them the link and password',
            url: routes.public.landing + `/${company_slug}/memo`,
          });
        } else {
          navigator.clipboard.writeText(
            window.location.origin + `/${company_slug}/memo`,
          );
          setCopiedToClipboard(true);
        }
      }}
    >
      <div className="px-4 ">
        {copiedToClipboard ? (
          <div className="flex items-center justify-center ">
            <CopyIcon />
            <div className="px-4 text- font-extrabold">
              Copied to clipboard!
            </div>
          </div>
        ) : (
          <>
            <div className="text-sm font-extrabold">Share your memo</div>
            <div className="text-xs pt-2">
              Share your memo with investors by sending them the link and
              password
            </div>
          </>
        )}
      </div>
      <div className=" pl-4">
        <div className="text-xs font-extrabold rounded-lg px-4 py-1">
          <ShareIcon />
        </div>
      </div>
    </ButtonCard>
  );
}
