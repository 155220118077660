import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GrantInbox from '../components/Grants/GrantInbox';
import HomeNavBar from '../components/Home/HomeNavBar';

import { GrantContext } from '../contexts/GrantContext';

import Loading from './Loading';
import { routes } from './routes';
import { LoggedInContext } from '../contexts/LoggedInContext';
import { track } from '../interfaces/mixpanel';

export default function MyGrants() {
  const { isLoading, isAuthenticated, company_slug } =
    useContext(LoggedInContext);
  const { companyParams, getCompanyParams, defineSelectedGrant, grantMatches } =
    useContext(GrantContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // on load record view
  React.useEffect(() => {
    track('My Grants view');
  }, []);

  useEffect(() => {
    setLoading(true);
    // if no company slug, then must be a new user
    if (!isLoading && company_slug === '') {
      navigate(routes.private.onboarding);
    }

    (async () => {
      await getCompanyParams();
    })();

    setLoading(false);
  }, [isLoading, isAuthenticated]);

  // if there are any unverified params then ask user to verify
  const unverifiedParams =
    companyParams?.parameters?.filter(param => param.userVerified === false) ||
    [];
  if (unverifiedParams?.length) {
    // ask user to verify params
    navigate(routes.private.myGrantsVerify);
  }

  useEffect(() => {
    // if the url has an grant id then set the selected grant
    setSelectedGrantFromUrl();
  }, [grantMatches]);

  const setSelectedGrantFromUrl = async () => {
    // if the url has an grant id then set the selected grant
    // get the if from the url, after my/grants/
    const url = window.location.href;
    const urlSplit = url.split(routes.private.myGrants)[1];
    // get the guid from the url
    const grantId = urlSplit.slice(-36);

    if (grantId && grantMatches) {
      // get the grant from the grant matches object (which has keys of grant status)
      Object.keys(grantMatches).forEach(async status => {
        if (
          'inProgress' === status ||
          'new' === status ||
          'archived' === status ||
          'awaiting' === status
        ) {
          const grant = grantMatches[status].find(
            (grant: { id: string }) => grant.id === grantId,
          );

          if (grant) {
            // set the selected grant
            await defineSelectedGrant({ grant, status });
            return;
          }
        }
      });
    }
  };

  if (loading || !companyParams?.parameters) {
    // wait for the params to load
    return <Loading size="full-page" />;
  }

  return (
    <div className="h-full min-h-[100vh]">
      <HomeNavBar />

      <GrantInbox />
    </div>
  );
}

{
  /* <ReviewGrantCard
        grantInfo={{
          name: 'Zayed Future Prize',
          logo: 'http://www.aldiplomasy.com/en/wp-content/uploads/2018/01/ZFEP-logo-HD_1515746406.jpg',
          summary:
            'The Zayed Future Energy Prize is a US$3 million award for entrepreneurs that celebrates achievements in renewable energy and sustainability. Applications close on 11th June 2020 and are open to all countries in The Americas, Europe, Africa, Oceania and Asia. The prize is divided into 5 categories: Health, Food, Energy, Water and Global High Schools. Eligibility criteria include that entrants must not be board members or employees of Masdar or anyone involved in organising, promoting or judging the Prize.',
          deadline: '31/01/23',
          prize: '$600,000',
          questions: [
            {
              question:
                'Do low-income segments represent at least 30% of your users or beneficiaries?',
              type: 'boolean',
            },
            {
              question: 'What is your one sentence pitch?',
              type: 'text',
              max_chara_length: 140,
              generated_answer: '',
            },
            {
              question:
                'Describe the specific problem(s) you are aiming to solve',
              type: 'text',
              generated_answer: ``,
            },
          ],
        }}
      /> */
}
