import React, { useContext } from 'react';
import Toggle from '../utils/SimpleToggle';
import Regenerate from '../utils/Regenerate';
import EditTextButtons from '../utils/EditTextButtons';
import StarIcon from '../Icons/StarIcon';
import { LoggedInContext } from '../../contexts/LoggedInContext';
import { useAuth0 } from '@auth0/auth0-react';
import { apiUrl } from '../../methods/env';
import { generateText } from '../../methods/generate';
import { LendingProductApplicationQuestion } from '../../contexts/ApplicationContext';
import TableItemsQuestion from './QuestionComponents/TableItemsQuestion';
import UploadQuestions from './QuestionComponents/UploadQuestions';

interface Props {
  question: LendingProductApplicationQuestion;
  saveAnswer: (answer: string) => void;
  isPublicApplication?: boolean;
}

export default function LendingApplicationQuestion(props: Props) {
  const { question } = props;
  const [showRegen, setShowRegen] = React.useState(false);
  const [rowText, setRowText] = React.useState(question.answer);
  const { company_slug } = useContext(LoggedInContext);
  const { getAccessTokenSilently } = useAuth0();

  // if company_slug exists allow regeneration
  React.useEffect(() => {
    if (!company_slug) setShowRegen(false);
  }, [company_slug]);

  // when props change update the rowText
  React.useEffect(() => {
    setRowText(question.answer);
  }, [question.answer]);

  const regenerateText = async () => {
    const auth0Token = await getAccessTokenSilently({
      audience: apiUrl,
    });

    const answer = await generateText({
      prompt: question?.generation_prompt || '',
      previousText: rowText || '',
      company_slug,
      label: question.label || '',
      auth0Token,
    });
    setRowText(answer);
    props.saveAnswer(answer);
  };

  return (
    <>
      {showRegen ? (
        <Regenerate
          saveFunction={text => {
            setRowText(text);
            props.saveAnswer(text);
          }}
          previousText={rowText || ''}
          label={question.label}
          closeFunction={() => setShowRegen(false)}
        />
      ) : (
        <>
          {question.type === 'long_question' ? (
            <div
              className="py-2 pb-6 text-sm sm:text-m "
              key={question.id + question.answer}
            >
              <div className="p-2">
                <b>{question.question}</b>
              </div>
              <div className="flex justify-between flex-row relative bg-stone-50 border border-1 border-solid border-gray-300 text-gray-900 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 block w-full">
                <div
                  className="block w-full p-2 "
                  contentEditable
                  suppressContentEditableWarning={true}
                  onBlur={async e => {
                    if (e.target.innerText === '' && rowText === undefined) {
                      return; // don't save if empty and no previous text as must be a miss-click
                    }
                    if (e.target.innerText === rowText) {
                      return;
                    }

                    await props.saveAnswer(e.target.innerText);
                    setRowText(e.target.innerText);
                  }}
                >
                  {rowText}
                </div>
                {question.disable_regeneration ? null : company_slug.length ? (
                  <div className="pt-2 px-2">
                    <EditTextButtons
                      icon={<StarIcon />}
                      tooltipText={!rowText?.length ? 'Generate' : 'Regenerate'}
                      onClick={async () => {
                        // if no text, generate immediately
                        if (!rowText?.length) {
                          await regenerateText();
                        } else {
                          setShowRegen(true);
                        }
                      }}
                      fadeIn={false}
                      hiddenOnMobile={false}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          ) : question.type === 'table' ? (
            <table className="w-full max-w-[1000px] text-sm sm:text-m ">
              <tbody>
                <TableItemsQuestion
                  question={question}
                  saveAnswer={async text => {
                    await props.saveAnswer(text);
                  }}
                />
              </tbody>
            </table>
          ) : question.type === 'upload' ? (
            <div className="py-4 text-sm sm:text-m ">
              <UploadQuestions
                question={question}
                removeCardBackground
                saveAnswer={async text => {
                  await props.saveAnswer(text);
                }}
                isPublicApplication={props.isPublicApplication}
              />
            </div>
          ) : null}
        </>
      )}
    </>
  );
}
