import React from 'react';
import { useNavigate } from 'react-router-dom';
import UserProfile from '../utils/UserProfile';
import CompanyProfile from '../utils/CompanyProfile';
import { routes } from '../../pages/routes';
import VulaLogo from '../utils/VulaLogo';
import ArrowIcon from '../Icons/ArrowIcon';

export default function HomeNavBar() {
  const navigate = useNavigate();
  return (
    <div className=" p-1 sm:p-4 border-b border-solid border-gray-200 flex justify-center">
      <div className="max-w-[1500px] w-full flex flex-row justify-between sm:px-32">
        {
          // if user is on home page, show logo else show back button
          window.location.pathname === routes.private.home ? (
            <VulaLogo />
          ) : (
            <button
              className=" flex justify-center flex-row items-center pl-4"
              onClick={() => navigate(routes.private.home)}
            >
              <ArrowIcon />
              <div className="px-2 text-lg font-extrabold ">Back</div>
            </button>
          )
        }
        <div className="flex  items-center justify-center  rounded-lg  hover:bg-stone-100 p-2">
          <CompanyProfile />
          <UserProfile />
        </div>
      </div>
    </div>
  );
}
