import React, { Dispatch, SetStateAction } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { createContext, useContext, useEffect, useState } from 'react';
import { VulaCompanyAPI } from '../api/company';
import {
  CompanyGrantMatches,
  CompanyParameters,
  NewCompanyParameter,
  Grant,
  SelectedGrant,
  GrantCompanyMatchParameters,
} from '../types/Grants';
import { LoggedInContext } from './LoggedInContext';
import { apiUrl } from '../methods/env';

interface GrantContextProps {
  companyParams: CompanyParameters | undefined;
  getCompanyParams: () => Promise<void>;
  saveNewParam: (param: NewCompanyParameter) => Promise<void>;
  deleteParam: (param_id: string) => Promise<void>;
  grantEmail: string | undefined;
  getCompanyGrantNotificationEmail: () => Promise<void>;
  grantMatches: CompanyGrantMatches;
  getCompanyGrantMatches: () => Promise<void>;
  selectedGrant:
    | {
        grant: Grant;
        status: 'inProgress' | 'new' | 'archived' | 'awaiting';
      }
    | undefined;
  defineSelectedGrant: (
    selectedGrant?: SelectedGrant | undefined,
  ) => Promise<void>;
  updateGrantStatus?: (
    status: 'inProgress' | 'new' | 'archived' | 'awaiting',
  ) => Promise<void>;
  updateAnswer?: (question_id: string, answer: string) => Promise<void>;
  setQuestionErrors: Dispatch<SetStateAction<string[]>>;
  questionIdsWithErrors: string[];
  getPreviousQAs: () => Promise<null | undefined>;
  previousQAs: { id: string; question: string; answer: string }[];
  storeGrantQA: (question: string, answer: string) => Promise<null | undefined>;
  updateGrantQA: (
    question_id: string,
    new_answer: string,
  ) => Promise<null | undefined>;
  deleteGrantQA: (question_id: string) => Promise<null | undefined>;
  matchingCompanyGrantParams?: GrantCompanyMatchParameters[];
}

// Initialise with Context engine
export const GrantContext = createContext<GrantContextProps>({
  companyParams: undefined,
  getCompanyParams: async () => undefined,
  saveNewParam: async () => undefined,
  deleteParam: async () => undefined,
  grantEmail: undefined,
  getCompanyGrantNotificationEmail: async () => undefined,
  grantMatches: { new: [], inProgress: [], archived: [], awaiting: [] },
  getCompanyGrantMatches: async () => undefined,
  selectedGrant: undefined,
  defineSelectedGrant: async () => undefined,
  updateGrantStatus: async () => undefined,
  updateAnswer: async () => undefined,
  setQuestionErrors: () => undefined,
  questionIdsWithErrors: [],
  getPreviousQAs: async () => undefined,
  previousQAs: [],
  storeGrantQA: async () => undefined,
  updateGrantQA: async () => undefined,
  deleteGrantQA: async () => undefined,
  matchingCompanyGrantParams: [],
} as GrantContextProps);

const GrantProvider = ({ children }: { children: React.ReactNode }) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const { company_slug, getUserData } = useContext(LoggedInContext);
  const [matchingCompanyGrantParams, setMatchingCompanyGrantParams] = useState<
    GrantCompanyMatchParameters[]
  >([]);

  const [grantEmail, setCompanyGrantEmail] = useState<string | undefined>(
    undefined,
  );
  const [companyParams, setCompanyParams] = useState<
    CompanyParameters | undefined
  >(undefined);

  const [grantMatches, setGrantMatches] = useState<CompanyGrantMatches>({
    new: [],
    inProgress: [],
    archived: [],
    awaiting: [],
  });
  const [selectedGrant, setSelectedGrant] = useState<SelectedGrant | undefined>(
    undefined,
  );
  const [questionIdsWithErrors, setQuestionErrors] = useState<string[]>([]);
  const [previousQAs, setPreviousQAs] = React.useState([]);
  // on load check if companies parameters are verified and get params
  useEffect(() => {
    if (company_slug.length) {
      (async () => {
        await getCompanyParams();
        await getCompanyGrantNotificationEmail();
        await getCompanyGrantMatches();
      })();
    } else {
      (async () => {
        await getUserData();
      })();
    }
  }, []);

  const getCompanyParams = async () => {
    try {
      // get token from auth0
      const token = await getAccessTokenSilently();
      // get companies params
      const api = new VulaCompanyAPI({ token });

      // call to create the params
      const params = await api.getCompanyParameters(company_slug);

      // set the params
      setCompanyParams(params);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error(error);
      // This may be because there are no current params ie 404
      if (error?.response?.status === 404) {
        setCompanyParams({ parameters: [], company_id: '' });
      }
    }
  };

  const deleteParam = async (param_id: string) => {
    try {
      // get token from auth0
      const token = await getAccessTokenSilently();
      // call to delete the param
      const api = new VulaCompanyAPI({ token });
      await api.deleteCompanyParameter(company_slug, param_id);
    } catch (error) {
      console.error(error);
    }
  };

  const saveNewParam = async (param: NewCompanyParameter) => {
    try {
      // get token from auth0
      const token = await getAccessTokenSilently();
      // get companies params
      const api = new VulaCompanyAPI({ token });

      // call to create the params
      await api.saveCompanyParameters(company_slug, param);
    } catch (error) {
      console.error(error);
    }
  };

  const getCompanyGrantNotificationEmail = async () => {
    try {
      // get token from auth0
      const token = await getAccessTokenSilently();
      // get companies params
      const api = new VulaCompanyAPI({ token });

      // call to create the params
      const response = await api.getGrantNotificationEmail(company_slug);

      // set the email
      setCompanyGrantEmail(response.data.email);
    } catch (error) {
      console.error(error);
    }
  };

  const getCompanyGrantMatches = async () => {
    try {
      // get token from auth0
      const token = await getAccessTokenSilently();
      // get companies params
      const api = new VulaCompanyAPI({ token });

      // call to get company grant matches
      const matchesWithStatus = await api.getCompanyGrants(company_slug);
      // set the matches
      setGrantMatches(matchesWithStatus);
    } catch (error) {
      console.error(error);
    }
  };

  const setGrantCompanyMatchingParameters = async (grant_id: string) => {
    try {
      // get token from auth0
      const token = await getAccessTokenSilently();
      // get companies params
      const api = new VulaCompanyAPI({ token });

      // call to get company grant matches
      const matchingParams = await api.getGrantCompanyMatchingParameters(
        company_slug,
        grant_id,
      );
      if (matchingParams.data) {
        setMatchingCompanyGrantParams(matchingParams.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const defineSelectedGrant = async (selectedGrant?: SelectedGrant) => {
    if (selectedGrant?.grant) {
      // change the url to include the grant namd and id
      const url_extension =
        (
          selectedGrant?.grant.name.substring(0, 20).replaceAll(' ', '-') + '-'
        ).replaceAll('--', '-') + selectedGrant?.grant?.id || '';

      // if the user is authenticated then add /my/grants to the url
      if (isAuthenticated) {
        window.history.pushState('', '', `/my/grants/${url_extension}`);
      } else {
        window.history.pushState('', '', `/grants/${url_extension}`);
      }

      // get matching company grant params
      await setGrantCompanyMatchingParameters(selectedGrant?.grant.id);
    }

    // a wraper for the setSelectedGrant state function
    if (selectedGrant && selectedGrant.status === 'inProgress') {
      // get token from auth0
      const token = await getAccessTokenSilently();
      // get companies params
      const api = new VulaCompanyAPI({ token });

      // call to get company grant matches
      const grantApplicationSections = await api.getGrantApplication(
        company_slug,
        selectedGrant.grant.id,
      );
      if (grantApplicationSections.data) {
        // clone selected grant and append application_sections to selectedGrant.grant
        const grantWithApplicationSections = {
          ...selectedGrant.grant,
          application_sections: grantApplicationSections.data,
        };
        setSelectedGrant({
          ...selectedGrant,
          grant: grantWithApplicationSections,
        });
      } else {
        setSelectedGrant(selectedGrant);
      }
    } else {
      setSelectedGrant(selectedGrant);
    }
  };

  const updateGrantStatus = async (
    status: 'inProgress' | 'new' | 'archived' | 'awaiting',
  ) => {
    if (selectedGrant?.grant.id) {
      const token = await getAccessTokenSilently();
      const api = new VulaCompanyAPI({ token });
      try {
        await api.updateGrantStatus(
          company_slug,
          selectedGrant?.grant.id,
          status,
        );
        await getCompanyGrantMatches();
      } catch (error) {
        console.error(error);
      }
    } else {
      console.log('No selected grant to update status');
      return;
    }
  };

  const updateAnswer = async (answer: string, question_id: string) => {
    // get api token from auth0
    const auth0Token = await getAccessTokenSilently({
      audience: apiUrl,
    });
    const api = new VulaCompanyAPI({ token: auth0Token });
    if (selectedGrant?.grant?.id) {
      // update answer
      await api
        .updateGrantQuestionAnswer(
          company_slug,
          selectedGrant.grant.id,
          question_id,
          answer,
        )
        .then(async () => {
          await refreshSelectedGrantQAAs();
          if (questionIdsWithErrors.includes(question_id)) {
            // remove the error
            const newErrors = questionIdsWithErrors.filter(
              id => id !== question_id,
            );
            setQuestionErrors(newErrors);
          }
        });
    }
  };

  const refreshSelectedGrantQAAs = async () => {
    if (!selectedGrant) return null;
    // get token from auth0
    const token = await getAccessTokenSilently();
    // get companies params
    const api = new VulaCompanyAPI({ token });

    // call to get company grant matches
    const grantApplicationSections = await api.getGrantApplication(
      company_slug,
      selectedGrant.grant.id,
    );
    if (grantApplicationSections.data) {
      // clone selected grant and append application_sections to selectedGrant.grant
      const grantWithApplicationSections = {
        ...selectedGrant.grant,
        application_sections: grantApplicationSections.data,
      };
      setSelectedGrant({
        ...selectedGrant,
        grant: grantWithApplicationSections,
      });
    } else {
      setSelectedGrant(selectedGrant);
    }
  };

  const getPreviousQAs = async () => {
    if (!selectedGrant?.grant) return null;
    // get token from auth0
    const token = await getAccessTokenSilently();

    // call api to accept terms
    const api = new VulaCompanyAPI({ token });

    try {
      const previousQAs = await api.getCompanyGrantAskedQuestions(
        company_slug,
        selectedGrant.grant.id,
      );
      setPreviousQAs(previousQAs);
    } catch (e) {
      console.error(e);
    }
  };

  const storeGrantQA = async (question: string, answer: string) => {
    if (!selectedGrant?.grant.id) return null;
    // get token from auth0
    const token = await getAccessTokenSilently();

    // call api
    const api = new VulaCompanyAPI({ token });

    try {
      await api.saveCompanyGrantAskedQuestion({
        company_slug,
        grant_id: selectedGrant.grant.id,
        question,
        answer,
      });
      await getPreviousQAs();
    } catch (e) {
      console.error(e);
    }
  };

  const updateGrantQA = async (question_id: string, new_answer: string) => {
    if (!selectedGrant?.grant.id) return null;
    // get token from auth0
    const token = await getAccessTokenSilently();

    // call api
    const api = new VulaCompanyAPI({ token });

    try {
      await api.updateCompanyGrantAskedQuestion({
        company_slug,
        grant_id: selectedGrant.grant.id,
        question_id,
        answer: new_answer,
      });
      await getPreviousQAs();
    } catch (e) {
      console.error(e);
    }
  };

  const deleteGrantQA = async (question_id: string) => {
    if (!selectedGrant?.grant.id) return null;
    // get token from auth0
    const token = await getAccessTokenSilently();

    // call api
    const api = new VulaCompanyAPI({ token });

    try {
      await api.deleteCompanyGrantAskedQuestion({
        company_slug,
        grant_id: selectedGrant.grant.id,
        question_id,
      });
      await getPreviousQAs();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <GrantContext.Provider
      value={{
        companyParams,
        getCompanyParams,
        saveNewParam,
        deleteParam,
        grantEmail,
        getCompanyGrantNotificationEmail,
        grantMatches,
        getCompanyGrantMatches,
        selectedGrant,
        defineSelectedGrant,
        updateGrantStatus,
        updateAnswer,
        setQuestionErrors,
        questionIdsWithErrors,
        getPreviousQAs,
        previousQAs,
        storeGrantQA,
        updateGrantQA,
        deleteGrantQA,
        matchingCompanyGrantParams,
      }}
    >
      {children}
    </GrantContext.Provider>
  );
};

export default GrantProvider;
