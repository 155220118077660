import React from 'react';

import HomeNavBar from '../components/Home/HomeNavBar';
import InProgressCard from '../components/Home/InProgressCard';

import { useNavigate } from 'react-router-dom';
import { routes } from './routes';

import ShareIcon from '../components/Icons/ShareIcon';
import { track } from '../interfaces/mixpanel';
import InvestorsHome from './InvestorsHome';
import { useAuth0 } from '@auth0/auth0-react';

export default function Home() {
  // get user metadata from auth0
  const { user } = useAuth0();
  const navigate = useNavigate();

  const [isInvestor, setIsInvestor] = React.useState(false);

  // on load record view
  React.useEffect(() => {
    track('Home view');
    // check if user is investor

    if (user) {
      // this needs to be configured in auth0 actions
      const isInvestor = user['https://vula.vc/user_metadata.isInvestor'];

      if (isInvestor) {
        track('Investor home view');
        setIsInvestor(true);
      }
    }
  }, []);

  return (
    <div className="pb-12">
      <HomeNavBar />
      <div className=" max-w-[1200px] sm:w-[90%] m-auto px-2 sm:px-20">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-1">
          {isInvestor ? (
            <InvestorsHome />
          ) : (
            <>
              <InProgressCard
                headline="Your Grants"
                subtitle="searching for grant matches"
                background="https://vula-assets.s3.eu-west-2.amazonaws.com/hopeful.webp"
                onClick={() => navigate(routes.private.myGrants)}
              />
              <InProgressCard
                headline="Your Pitch"
                subtitle=""
                background="https://vula-assets.s3.eu-west-2.amazonaws.com/cubic.png"
                onClick={() => navigate(routes.private.pitchHome)}
                specialButton={
                  // special share button with icon that on click goes to routes.share
                  <button
                    className="flex flex-row items-center m-4 px-4 rounded hover:bg-stone-200 bg-stone-100"
                    onClick={event => {
                      event.stopPropagation(); // to stop the below button from being clicked
                      navigate(routes.private.sharePitch);
                    }}
                  >
                    <div className="text-small font-extrabold pr-4 py-1">
                      Share
                    </div>

                    <ShareIcon />
                  </button>
                }
              />
              <InProgressCard
                headline="Investor Memo"
                subtitle="a note for potential investors"
                background="https://images.unsplash.com/photo-1588941288445-b1a5f3977b9f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80"
                onClick={() => navigate(routes.private.memo)}
              />
              <InProgressCard
                headline="Ask Vula"
                subtitle="to answer any question"
                background="https://images.unsplash.com/photo-1587321174565-73cffc72e10a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2669&q=80"
                onClick={() => navigate(routes.private.ask)}
              />
              <InProgressCard
                headline="Funding Applications"
                subtitle="Get the best funding for you"
                background="https://images.unsplash.com/photo-1579621970563-ebec7560ff3e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2371&q=80"
                onClick={() => navigate(routes.private.applicationsHome)}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
