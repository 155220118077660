import React from 'react';
import { ApplicationContext } from '../../contexts/ApplicationContext';
import SectionNavigationButtons from './SectionNavigationButtons';

export default function ApplicationHeader() {
  const { lendingProductApplicationDefinition, applicationSectionNames } =
    React.useContext(ApplicationContext);
  return (
    <div className="flex flex-row items-center justify-between rounded-xl bg-white shadow my-2 py-2 ">
      <div className="w-full sm:w-auto sm:pr-12  sm:pl-4 flex flex-row justify-center sm:justify-start items-center">
        <img
          src={lendingProductApplicationDefinition?.Partner.logo}
          alt="logo"
          className="min-w-20 min-h-20 max-w-32 max-h-12 drop-shadow"
        />
      </div>
      <div className="hidden  sm:block grid gap-4 grid-cols-5  ">
        {/* Tabs to move between  "company info", "documents", "agreement” */}
        {applicationSectionNames?.map(tab => (
          <SectionNavigationButtons tab={tab} key={tab.section_title} />
        ))}
      </div>
    </div>
  );
}
