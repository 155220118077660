import React, { useContext, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import TypeWriterEffect from 'react-typewriter-effect';

import { apiUrl } from '../../methods/env';

import SingleLineInput from './SingleLineInput';
import { VulaCompanyAPI } from '../../api/company';
import { track } from '../../interfaces/mixpanel';

import { LoggedInContext } from '../../contexts/LoggedInContext';

import { checkIsValidUrl, modifyToAbsoluteUrl } from '../../methods/UIHelpers';
import { AxiosResponse } from 'axios';
import CheckCompanyDefinition from './CheckCompanyDefinition';

interface Props {
  incrementStep: (number: 1 | -1) => void;
}

export default function AskForURL(props: Props) {
  const { getAccessTokenSilently, user } = useAuth0();

  const {
    company_name,
    setCompanyName,
    setCompanySlug,
    setCompanyLogo,
    setCompanyElevatorPitch,
  } = useContext(LoggedInContext);

  const [showDupeCompanyMessage, setDupeCompany] = useState(false);
  const [showLoading, setLoading] = useState(false);
  const [showError, setError] = useState(false);
  const [givenCompanyURL, setGivenCompanyURL] = useState('');

  async function scrape_url(
    auth0Token: string,
    company_url: string,
    retries = 2,
  ) {
    try {
      const api = new VulaCompanyAPI({ token: auth0Token });
      const response = await api.saveCompanyUrl(company_url);
      cache_scraped_data(response);
      // eslint-disable-next-line
    } catch (err: any) {
      console.log('error');
      if (err?.response?.status === 422) {
        setLoading(false);
        setDupeCompany(true);
        track('Company creation (url) error - dupe.', {
          given_url: company_url,
          user_id: user?.sub,
        });
      }
      console.error(err);
      if (retries > 0) {
        // Retry the API call with one fewer retry
        scrape_url(auth0Token, company_url, retries - 1);
      } else {
        setLoading(false);
        setError(true);
        track('Company creation (url) error', {
          given_url: company_url,
          user_id: user?.sub,
        });
      }
    }
  }

  const cache_scraped_data = (response: AxiosResponse) => {
    const { company_full_name, company_slug, logo, elevator_pitch } =
      response.data;

    setCompanyName(company_full_name);
    setCompanySlug(company_slug);
    setCompanyLogo(logo);
    setCompanyElevatorPitch(elevator_pitch);
  };

  const save_company_url = async (url: string) => {
    // call the scrape api. If it fails, retry once.
    setLoading(true);
    const company_url = modifyToAbsoluteUrl(url);
    setGivenCompanyURL(company_url);
    const auth0Token = await getAccessTokenSilently({
      audience: apiUrl,
    });
    await scrape_url(auth0Token, company_url);
  };

  const findUrlFromUserEmail = () => {
    const email = user?.email;
    // split email on the @ and get the url after the @
    if (email) {
      const splitEmail = email.split('@');
      if (splitEmail.length > 1) {
        const disallowedURLs = [
          'gmail.com',
          'yahoo.com',
          'hotmail.com',
          'outlook.com',
          'icloud.com',
          'me.com',
          'aol.com',
          'msn.com',
          'live.com',
          'zoho.com',
          'protonmail.com',
          'yandex.com',
          'mail.com',
        ];
        // if the url is not in the disallowed list, return it
        if (splitEmail[1] && !disallowedURLs.includes(splitEmail[1])) {
          return splitEmail[1];
        } else return undefined;
      }
    }
  };

  return (
    <>
      {company_name.length || (givenCompanyURL && !showLoading) ? null : (
        <div className="bg-white rounded-3xl drop-shadow-lg p-8">
          {showDupeCompanyMessage ? (
            <div className="text-xl font-extrabold">
              It looks like we already have a company with this website. Please
              check the URL and try again.
            </div>
          ) : showLoading ? (
            <TypeWriterEffect
              startDelay={500}
              cursor=""
              cursorColor="#000000"
              multiText={[
                'Finding your website...',
                '',
                '',
                'Reading your home page...',
                '',
                '',
                'Storing the text from your site...',
                '',
                '',
                'Teaching Vula about your company...',
                '',
                '',
                'Vula is writing your elevator pitch...',
              ]}
              multiTextDelay={1200}
              typeSpeed={40}
            />
          ) : (
            <>
              <div className="font-extrabold text-2xl pb-2">
                Enter your website URL
              </div>
              <div>Vula will then fill out the questions below for you.</div>
              <div className="sm:w-1/2">
                <SingleLineInput
                  oneline={true}
                  placeholder="yourwebsite.com"
                  initialValue={findUrlFromUserEmail()}
                  buttonText="Submit"
                  onSubmit={(url: string) => save_company_url(url)}
                  validator={(url: string) => checkIsValidUrl(url)}
                />
              </div>
            </>
          )}
        </div>
      )}
      <div className="pt-12 text-xl font-extrabold ">
        {showError
          ? "Vula couldn't be trained, please complete the below:"
          : showLoading
          ? "Vula's working on it..."
          : company_name.length || (givenCompanyURL && !showLoading)
          ? 'Is this right?'
          : 'Or add the data manually.'}
      </div>
      <CheckCompanyDefinition incrementStep={props.incrementStep} />
    </>
  );
}
