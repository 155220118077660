import axios from 'axios';

import { apiUrl } from '../methods/env';

type Props = {
  token?: string;
};

export class VulaLendingAPI {
  private token?: string;

  constructor(props?: Props) {
    this.token = props?.token;
  }

  getConfig() {
    const config = {
      headers: { Authorization: `Bearer ${this.token}` },
    };
    return config;
  }

  async getLendingProducts(company_slug: string) {
    const config = this.getConfig();
    // GET /lending/:company_slug
    return axios.get(`${apiUrl}/lending/${company_slug}`, config);
  }

  async getLendingProduct(
    company_slug: string,
    partner_name: string,
    product_code: string,
  ) {
    const config = this.getConfig();
    // GET /lending/:company_slug/:partner_name/:product_code
    return axios.get(
      `${apiUrl}/lending/${company_slug}/${partner_name}/${product_code}`,
      config,
    );
  }

  async updateStatusOfLendingProductApplicationForCompany(
    company_slug: string,
    partner_name: string,
    product_name: string,
    status: string,
  ) {
    const config = this.getConfig();
    // PUT /lending/:company_slug/:partner_name/:product_name/:application_id
    return axios.put(
      `${apiUrl}/lending/${company_slug}/${partner_name}/${product_name}`,
      { status },
      config,
    );
  }
}
