import React, { useContext } from 'react';
import { ApplicationContext } from '../../contexts/ApplicationContext';
import LendingApplicationQuestion from './LendingApplicationQuestion';
import { EditPitchContext } from '../../contexts/EditPitchContext';
import TableItemsQuestion from './QuestionComponents/TableItemsQuestion';
import RepeatingQsCard from './QuestionComponents/RepeatingQsCard';
import UploadQuestions from './QuestionComponents/UploadQuestions';

export default function CompanyInfo() {
  const { lendingProductApplicationDefinition, updateAnswerInApplicationJson } =
    useContext(ApplicationContext);

  const { updateCorpus, updateCompanyDetails } = useContext(EditPitchContext);

  const company_info_section =
    lendingProductApplicationDefinition?.LendingProductApplicationSection?.find(
      section => section.type === 'company_info',
    );

  const tableItems = company_info_section?.section_questions.filter(
    question => question.type === 'table' && question.repeating_label === null,
  );

  const questions = company_info_section?.section_questions.filter(
    question =>
      (question.type === 'long_question' || question.type === 'upload') &&
      question.repeating_label === null,
  );

  // group all the repeating questions together, their repeating_label will be the same
  const repeatingQuestions = company_info_section?.section_questions.filter(
    question => question.repeating_label,
  );
  repeatingQuestions?.sort((a, b) => a.order - b.order);
  // group repeating questions on their label

  return (
    <div>
      <div className="text-4xl font-extrabold">
        {company_info_section?.section_title}
      </div>

      <div className="mb-8 mt-2">{company_info_section?.section_subtitle}</div>

      <div className="text-2xl font-extrabold mt-8">Basic Business Info</div>
      {/* table questions */}
      <tbody className=" max-w-[1000px]">
        {tableItems?.map(item => (
          <TableItemsQuestion
            question={item}
            saveAnswer={async text => {
              updateAnswerInApplicationJson(item.id, text);
              if (item.answer_source === 'corpus')
                await updateCorpus(item.label, text);
              else if (item.answer_source === 'company_details')
                await updateCompanyDetails(item.label, text);
            }}
          />
        ))}
      </tbody>

      {/* repeated questions */}
      {repeatingQuestions?.length ? (
        <>
          <div className="text-2xl font-extrabold mt-8">
            Definition of Business
          </div>
          <div className="my-2">
            These questions need to be answered for each relevant subject.
          </div>

          <RepeatingQsCard />
        </>
      ) : null}

      {/* long form questions */}
      <div className=" max-w-[1000px]">
        <div className="text-2xl font-extrabold my-8">Company objectives</div>

        {questions &&
          questions.map(question =>
            question.type === 'upload' ? (
              <UploadQuestions
                question={question}
                removeCardBackground
                saveAnswer={text =>
                  updateAnswerInApplicationJson(question.id, text)
                }
              />
            ) : (
              <LendingApplicationQuestion
                question={question}
                saveAnswer={async text => {
                  updateAnswerInApplicationJson(question.id, text);
                  if (question.answer_source === 'corpus')
                    await updateCorpus(question.label, text);
                  else if (question.answer_source === 'company_details')
                    await updateCompanyDetails(question.label, text);
                }}
              />
            ),
          )}
      </div>
    </div>
  );
}
