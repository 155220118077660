import { useAuth0 } from '@auth0/auth0-react';
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  SetStateAction,
  Dispatch,
} from 'react';
import { useLocation } from 'react-router-dom';
import { VulaCompanyAPI } from '../api/company';
import { VulaCorpusAPI } from '../api/corpus';
import { VulaPitchAPI } from '../api/pitch';
import {
  NonStickyHelperMessages,
  SectionHelperMessages,
  PageWelcomeMessages,
} from '../components/Pitch/SimpleHelperEnums';
import { CompanyPitch, PublicWelcomePage } from '../data/companies';
import { apiUrl } from '../methods/env';
import { replaceHTMLEncoding } from '../methods/UIHelpers';
import { LoggedInContext } from './LoggedInContext';
import { routes } from '../pages/routes';

interface EditPitchContextProps {
  userIsAdmin: boolean;
  editMode: boolean;
  setEditMode: (status: boolean) => void;
  investorMode: boolean;
  setInvestorMode: (status: boolean) => void;
  interactiveMessage: string;
  setEditNotificationMessage: (status: string) => void;
  setPageMessage: (message: string) => void;
  updateCorpus: (label: string, answer: string) => void;
  addToCorpus: (label: string, answer: string, question: string) => void;
  addToStructuredData: (
    company_slug: string,
    label: string,
    content: string,
  ) => void;
  pageSlug: string;
  getPitchData: () => Promise<void> | undefined;
  publicPitchInfo: PublicWelcomePage | undefined;
  setPublicPitchInfo: Dispatch<SetStateAction<PublicWelcomePage | undefined>>;
  privatePitchInfo: CompanyPitch | undefined;
  setPrivatePitchInfo: Dispatch<SetStateAction<CompanyPitch | undefined>>;
  pitchPasswords: string[] | undefined;
  getPitchPasswords: () => Promise<string | undefined>;
  pitchIsPublic: boolean;
  updateCompanyDetails: (label: string, content: string) => void;
}

// Initialise with Context engine
export const EditPitchContext = createContext<EditPitchContextProps>({
  userIsAdmin: false,
  editMode: false,
  setEditMode: () => undefined,
  investorMode: false,
  setInvestorMode: () => undefined,
  interactiveMessage: '',
  setEditNotificationMessage: () => undefined,
  setPageMessage: () => undefined,
  updateCorpus: async () => undefined,
  addToCorpus: async () => undefined,
  addToStructuredData: () => undefined,
  pageSlug: '',
  getPitchData: async () => undefined,
  publicPitchInfo: undefined,
  setPublicPitchInfo: () => undefined,
  privatePitchInfo: undefined,
  setPrivatePitchInfo: () => undefined,
  pitchPasswords: undefined,
  getPitchPasswords: async () => undefined,
  pitchIsPublic: false,
  updateCompanyDetails: async () => undefined,
} as EditPitchContextProps);

const EditPitchProvider = ({ children }: { children: React.ReactNode }) => {
  // link to other contexts
  const { company_slug, isLoading } = useContext(LoggedInContext);
  const { getAccessTokenSilently } = useAuth0();

  // track the pathname the user is on
  const location = useLocation();
  const [pageSlug, setPageSlug] = useState(
    location.pathname.toString().toLowerCase(),
  );

  // initial value set
  const initialWelcomeMessage = PageWelcomeMessages.pitchWelcome;
  const [userIsAdmin, setUserIsAdmin] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [timerId, setTimerId] = useState<NodeJS.Timeout | undefined>(undefined);
  const [investorMode, setInvestorMode] = useState(false);
  const [tempMessage, setTempMessage] = useState('');
  const [stickyMessage, setStickyMessage] = useState(initialWelcomeMessage);
  const [publicPitchInfo, setPublicPitchInfo] = useState<
    PublicWelcomePage | undefined
  >(undefined);
  const [privatePitchInfo, setPrivatePitchInfo] = useState<
    CompanyPitch | undefined
  >(undefined);
  const [pitchPasswords, setPitchPasswords] = useState<string[] | undefined>(
    undefined,
  );
  const [pitchIsPublic, setPitchIsPublic] = useState(false);

  // on load decide whether edits should be allowed
  useEffect(() => {
    if (!isLoading) {
      (async () => {
        await checkUserIsAdmin();
      })();
    }
  }, [isLoading]);

  // update pageslug as user navigates
  useEffect(() => {
    setPageSlug(location.pathname.toString().toLowerCase());
    (async () => {
      await checkUserIsAdmin();
    })();
  }, [location, company_slug]);

  // when pageMessage changes update the visible messaage
  useEffect(() => {
    setTempMessage(stickyMessage);
  }, [stickyMessage]);

  // when interactiveMessage appears only allow it to show for max 8 seconds
  useEffect(() => {
    setMessageAndTTL();
  }, [tempMessage]);

  // check if user is admin
  const checkUserIsAdmin = async () => {
    // if no company slug then must not be signed in
    // a proxy for admin rights is getting the company info from the users token.
    // This^ is done in LoggedInContext
    if (!company_slug.length) {
      // check if they are trying to get to one of the app pages
      if (
        Object.values(routes.private)
          .concat(Object.values(routes.public))
          ?.includes(pageSlug)
      ) {
        // if so, no action needed, they will be routed to signup via protected route
        // console.log('not in pages');
        return;
      }

      if (pageSlug.includes('memo')) {
        await getPitchData(pageSlug.split('/memo')[0].replaceAll('/', ''));
        return;
      }

      // otherwise they are trying to get to a pitch page
      await getPitchData(pageSlug.replace('/', ''));
      return false;
    }
    if (!pageSlug.length) {
      // console.log('no page slug');
      return false;
    }

    // if viewing in the company page and the company slug matches the page slug then allow edits
    if (pageSlug === company_slug) {
      // console.log('slug match');
      setUserIsAdmin(true);
      setEditMode(true);
      await getPitchData(company_slug);
      return true;
    }

    // if viewing in the pitch page
    if (
      pageSlug.includes(routes.private.pitchHomeComponents.replaceAll('*', ''))
    ) {
      // console.log('pitch url');
      await getPitchData(company_slug);
      setEditMode(true);
      setUserIsAdmin(true);
      return true;
    }

    // if viewing in the home page
    if (pageSlug === routes.private.home) {
      // console.log('home');
      setEditMode(true);
      setUserIsAdmin(true);
      return true;
    }
    // if viewing in the onboarding page
    if (pageSlug === routes.private.onboarding) {
      // console.log('onboarding');
      setEditMode(true);
      setUserIsAdmin(true);
      return true;
    }

    // if none of the above and they are somewhere on the site then they must be an admin
    if (Object.values(routes.private).includes(pageSlug)) {
      // ^remove the first element of ruotes as it is the landing page
      // console.log('home url');
      setUserIsAdmin(true);
      return true;
    }
    return false;
  };

  // get pitch password(s)
  const getPitchPasswords = async () => {
    const token = await getAccessTokenSilently();
    const api = new VulaCompanyAPI({ token });
    try {
      const res = await api.getCompanyPitchPassword(company_slug);
      setPitchPasswords(res.data.passphrase);
      setPitchIsPublic(res.data.public);
      return res.data;
    } catch (error) {
      return;
    }
  };

  // get pitch
  const getPitchData = async (slug?: string) => {
    if (isLoading) return;
    if (slug?.includes('/')) {
      return;
    }

    await getAccessTokenSilently({
      audience: apiUrl,
    })
      .then(async auth0Token => {
        if (!slug || slug === '') {
          // maybe an investor so get the pitch data
          slug = company_slug;
        }
        if (!slug || slug === '') {
          return;
        }

        if (auth0Token) {
          const pitchApi = new VulaPitchAPI({ token: auth0Token });

          // get public pitch data and store it in state
          await pitchApi.getPublicPitch({ slug }).then(res => {
            // save in state
            setPublicPitchInfo(res.data);
          });

          // get private pitch data and store it in state
          await pitchApi.getPitch({ slug }).then(res => {
            setPrivatePitchInfo(res.data);
          });
        }
      })
      .catch(async () => {
        if (pageSlug === routes.private.onboarding) {
          return;
        }
        if (!slug || slug === '') {
          // maybe an investor so get the pitch data
          slug = pageSlug;
        }
        if (!slug || slug === '') {
          return;
        }
        // an investor must be viewing
        const pitchApi = new VulaPitchAPI();

        // get public pitch data and store it in state
        await pitchApi
          .getPublicPitch({ slug })
          .then(res => {
            // save in state
            setPublicPitchInfo(res.data);
          })
          .catch(error => {
            console.log(error);
          });
      });
  };

  const setEditNotificationMessage = (message: string) => {
    // set the message
    setTempMessage(message);
  };

  const setMessageAndTTL = () => {
    // if a timer is already running cancel it
    if (timerId) {
      clearTimeout(timerId);
    }
    // for messages that are not sticky
    // set a timer to remove the message after a short period of time
    if (!Object.values(SectionHelperMessages).includes(tempMessage)) {
      setTimerId(
        // create a timer to clear after a short period of time
        setTimeout(() => {
          if (tempMessage.length) {
            setEditNotificationMessage(stickyMessage);
          }
        }, 1500),
      );
    }
  };

  // allow updating of corpus
  const updateCorpus = async (
    label: string,
    answer: string,
    question?: string,
  ) => {
    const token = await getAccessTokenSilently();
    const api = new VulaCorpusAPI({ token: token });

    try {
      setEditNotificationMessage(NonStickyHelperMessages.saving);
      const res = await api.updateCorpus({
        slug: company_slug,
        label: label,
        answer: replaceHTMLEncoding(answer),
        question: question,
      });
      if (res.status === 200) {
        // show saved
        setEditNotificationMessage(NonStickyHelperMessages.saved);
        // refresh the pitch data from db
        await getPitchData(company_slug);
      } else {
        // warn - try again
        setEditNotificationMessage(NonStickyHelperMessages.failed);
      }
    } catch (error) {
      console.error(error);
      // warn - try agian
      setEditNotificationMessage(NonStickyHelperMessages.failed);
    }
  };

  // allow adding new to corpus
  const addToCorpus = async (
    label: string,
    answer: string,
    question: string,
  ) => {
    const token = await getAccessTokenSilently();
    const api = new VulaCorpusAPI({ token: token });
    try {
      setEditNotificationMessage(NonStickyHelperMessages.saving);
      const res = await api.addToCorpus({
        slug: company_slug,
        label: label,
        answer: replaceHTMLEncoding(answer),
        question: question,
      });
      if (res.status === 200) {
        // show saved
        setEditNotificationMessage(NonStickyHelperMessages.saved);
        // refresh the pitch data from db
        await getPitchData(company_slug);
      } else {
        // warn - try again
        setEditNotificationMessage(NonStickyHelperMessages.failed);
      }
    } catch (error) {
      console.error(error);
      // warn - try agian
      setEditNotificationMessage(NonStickyHelperMessages.failed);
    }
  };

  // allow adding to structured data
  const addToStructuredData = async (
    company_slug: string,
    label: string,
    content: string,
  ) => {
    const token = await getAccessTokenSilently();
    const api = new VulaCompanyAPI({ token });
    setEditNotificationMessage(NonStickyHelperMessages.saving);

    await api
      .updateCompanyStructuredData(
        company_slug,
        label,
        replaceHTMLEncoding(content),
      )
      .then(async () => {
        setEditNotificationMessage(NonStickyHelperMessages.saved);
        // refresh the pitch data from db
        await getPitchData(company_slug);
      })
      .catch(() => {
        setEditNotificationMessage(NonStickyHelperMessages.failed);
      });
  };

  const updateCompanyDetails = async (label: string, content: string) => {
    const token = await getAccessTokenSilently();
    const api = new VulaCompanyAPI({ token });
    setEditNotificationMessage(NonStickyHelperMessages.saving);

    await api
      .updateCompanyDetails(company_slug, label, replaceHTMLEncoding(content))
      .then(async () => {
        setEditNotificationMessage(NonStickyHelperMessages.saved);
      })
      .catch(() => {
        setEditNotificationMessage(NonStickyHelperMessages.failed);
      });
  };

  return (
    <EditPitchContext.Provider
      value={{
        userIsAdmin,
        editMode,
        setEditMode,
        investorMode,
        setInvestorMode,
        interactiveMessage: tempMessage,
        setEditNotificationMessage,
        setPageMessage: setStickyMessage,
        updateCorpus,
        addToCorpus,
        addToStructuredData,
        pageSlug,
        getPitchData,
        publicPitchInfo,
        setPublicPitchInfo,
        privatePitchInfo,
        setPrivatePitchInfo,
        pitchPasswords,
        getPitchPasswords,
        pitchIsPublic,
        updateCompanyDetails,
      }}
    >
      {children}
    </EditPitchContext.Provider>
  );
};

export default EditPitchProvider;
