import React, { useContext, useEffect, useState } from 'react';

import { LoggedInContext } from '../../contexts/LoggedInContext';

export default function Profile() {
  const { company_logo, company_name } = useContext(LoggedInContext);

  const [imageFailed, setImageFailed] = useState(false);

  // if company name or logo updates rerender
  useEffect(() => {
    setImageFailed(false);
  }, [company_name, company_logo]);

  if (imageFailed || company_logo.length === 0) {
    return <></>;
  }

  return (
    <img
      className="rounded-full object-contain mx-1"
      src={company_logo}
      alt={company_name}
      style={{ width: '25px', height: '25px' }}
      onError={() => setImageFailed(true)}
    />
  );
}
