import React from 'react';
import { LendingProductApplicationQuestion } from '../../../contexts/ApplicationContext';
import UploadZone from '../../utils/UploadZone';
import UploadZonePublic from '../../utils/UploadZonePublic';

interface Props {
  question: LendingProductApplicationQuestion;
  saveAnswer: (answer: string) => void;
  removeCardBackground?: boolean;
  isPublicApplication?: boolean;
}
export default function UploadQuestions(props: Props) {
  const { question } = props;
  return (
    <div
      key={question.id}
      className={
        props.removeCardBackground
          ? ''
          : 'bg-stone-50 drop-shadow rounded-lg p-4 border  border-1  border-solid border-stone-300'
      }
    >
      <div className="relative pl-2 text-sm sm:text-m font-bold items-center group">
        {question.question}
        {question.additional_info && (
          <>
            <img
              src="https://www.svgrepo.com/show/524661/info-square.svg"
              className="w-4 h-4 inline-block group-hover:hidden ml-2 "
            />

            <span className="p-1 px-3 mx-2  text-xs rounded-full drop-shadow bg-white font-medium group-hover:inline-block hidden">
              {question.additional_info}
            </span>
          </>
        )}
      </div>

      {typeof question.answer === 'string' && question.answer.length ? (
        <div className="mt-4">
          <a href={question.answer} target="_blank" rel="noopener noreferrer">
            <img
              className="w-20 h-20 inline-block mr-2"
              src={'https://www.svgrepo.com/show/532762/file-check-alt.svg'}
            />
          </a>
        </div>
      ) : null}

      <div className="mt-1">
        {typeof question.answer === 'string' && question.answer?.length ? (
          <button
            className="bg-stone-50 hover:bg-stone-100 rounded-lg p-2"
            onClick={() => props.saveAnswer('')}
          >
            Upload New
          </button>
        ) : props.isPublicApplication ? (
          <UploadZonePublic
            label={question.label}
            afterUpload={() => props.saveAnswer('file uploaded')}
          />
        ) : (
          <UploadZone
            label={question.label}
            afterUpload={() => props.saveAnswer('file uploaded')} 
          />
        )}
      </div>
    </div>
  );
}
