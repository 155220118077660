import React, { useContext } from 'react';
import EditTextButtons from '../utils/EditTextButtons';
import StarIcon from '../Icons/StarIcon';
import Regenerate from '../utils/Regenerate';
import BinIcon from '../Icons/BinIcon';
import { MemoContext } from '../../contexts/MemoContext';
import { generateText } from '../../methods/generate';
import { LoggedInContext } from '../../contexts/LoggedInContext';
import { useAuth0 } from '@auth0/auth0-react';
import { apiUrl } from '../../methods/env';

interface Props {
  saveAnswer: (answer: string) => void;
  text: string;
  label: string;
  disableRegen?: boolean;
  generationPrompt?: string;
}

export default function EditableMemoText(props: Props) {
  // context
  const { deleteRow } = useContext(MemoContext);
  const { company_slug } = useContext(LoggedInContext);
  const { getAccessTokenSilently } = useAuth0();

  //state
  const [showRegen, setShowRegen] = React.useState(false);
  const [rowText, setRowText] = React.useState(props.text);

  const regenerateText = async () => {
    const auth0Token = await getAccessTokenSilently({
      audience: apiUrl,
    });

    const answer = await generateText({
      prompt: props.generationPrompt || '',
      previousText: rowText,
      company_slug,
      label: props.label || '',
      auth0Token,
    });
    setRowText(answer);
    props.saveAnswer(answer);
  };

  return (
    <div className="group flex items-center hover:bg-stone-100  ">
      {showRegen ? (
        <Regenerate
          saveFunction={text => props.saveAnswer(text)}
          previousText={rowText}
          label={props.label}
          closeFunction={() => setShowRegen(false)}
        />
      ) : (
        <>
          <div
            contentEditable={true}
            suppressContentEditableWarning={true}
            onBlur={event => {
              if (rowText !== event.target.innerText) {
                props?.saveAnswer(event.target.innerText);
              }
            }}
            className={' p-2 w-full '}
            dangerouslySetInnerHTML={{
              __html: rowText || '',
            }}
          ></div>
          {props.disableRegen ? null : (
            <EditTextButtons
              icon={<StarIcon />}
              tooltipText={!rowText.length ? 'Generate' : 'Regenerate'}
              onClick={async () => {
                // if no text, generate immediately
                if (!rowText.length) {
                  await regenerateText();
                } else {
                  setShowRegen(true);
                }
              }}
              fadeIn={true}
              hiddenOnMobile={false}
            />
          )}
          {deleteRow ? (
            <EditTextButtons
              icon={<BinIcon />}
              tooltipText="Delete row"
              onClick={async () => await deleteRow(props.label)}
              fadeIn={true}
              hiddenOnMobile={true}
            />
          ) : null}
        </>
      )}
    </div>
  );
}
