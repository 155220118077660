import React, { useEffect, useContext, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { apiUrl } from '../../methods/env';
import { VulaUserAPI } from '../../api/user';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../pages/routes';
import { LoggedInContext } from '../../contexts/LoggedInContext';
import { track } from '../mixpanel';
import Loading from '../../pages/Loading';
import SingleLineInput from '../../components/Onboarding/SingleLineInput';
import VulaLogo from '../../components/utils/VulaLogo';

export const OnLogin = () => {
  const {
    getAccessTokenSilently,
    loginWithRedirect,
    user,
    isLoading,
    isAuthenticated,
  } = useAuth0();
  const navigate = useNavigate();
  const { setCompanySlug, setCompanyName, getUserData } =
    useContext(LoggedInContext);
  const [showError, setErrorVisibility] = useState(false);
  const [error, setError] = useState('');
  const [showLoading, setLoadingVisibility] = useState(false);
  const [referrer, setReferrer] = useState('');
  const [vula_claim_code, setVulaClaimCode] = useState('');

  // on page load
  useEffect(() => {
    // GET THE REFERRER
    let referrer = window.location.search.split('ref=')[1] || '';
    if (referrer.includes('&')) referrer = referrer.split('&')[0];
    setReferrer(referrer);

    // get the vula_claim_code
    let vula_claim_code =
      window.location.search.split('vula_claim_code=')[1] || '';
    if (vula_claim_code.includes('&'))
      vula_claim_code = vula_claim_code.split('&')[0];
    setVulaClaimCode(vula_claim_code);

    setLoadingVisibility(true);

    // cant immediately call checkUserExists as we need to wait for the user to be loaded from auth0
    if (!isLoading) {
      (async () => {
        // check if user exists in db already?
        await checkUserExists();
      })();
    }
  }, []);

  useEffect(() => {
    //
    if (!isLoading) {
      // CAN A USER BE FALSE AND LOADING BE FALSE?
      (async () => {
        // check if user exists in db already?
        await checkUserExists();
      })();
    }
  }, [isLoading, user]);

  const getAuth0Token = async () => {
    try {
      const auth0Token = await getAccessTokenSilently({ audience: apiUrl });
      return auth0Token;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (e.error === 'login_required' || e.error === 'consent_required') {
        // call this after 1 second to stop jumping page loading
        setTimeout(async () => {
          await loginWithRedirect();
          const auth0Token = await getAccessTokenSilently({ audience: apiUrl });
          return auth0Token;
        }, 1000);
      }

      console.log('Failed getting token silently', e.message);
      track('signup error', {
        message: 'Failed to get auth0 token',
        error: JSON.stringify(e),
        user_id: user?.sub,
        email: user?.email,
      });
      setError(e.message);
      setErrorVisibility(true);
      console.log(e);
      throw e;
    }
  };

  const checkUserExists = async () => {
    // will delete after testing
    track('Checking user exists', {
      user_id: user?.sub,
      email: user?.email,
      isLoading,
      isAuthenticated,
    });

    const auth0Token = await getAuth0Token();
    if (!auth0Token) {
      setErrorVisibility(true);
      return;
    }
    const api = new VulaUserAPI({ token: auth0Token });

    await api
      .getUserMe()
      .then(res => {
        setLoadingVisibility(false);
        // if a user who has correctly onboarded
        if (res.data.CompanyAdmins.length) {
          // set the provider context
          setCompanyName(res.data.CompanyAdmins[0].Company.company_full_name);
          setCompanySlug(res.data.CompanyAdmins[0].Company.company_slug);
          navigate(routes.private.home);
        } else {
          navigate(routes.private.onboarding);
        }
      })
      .catch(async e => {
        // if no user exists create one
        if (e?.response?.status === 404) {
          await createNewUser();
          await getUserData();
        } else {
          track('Login error', {
            message: 'Error other than 404',
            error: JSON.stringify(e),
            user_id: user?.sub,
          });
          setErrorVisibility(true);
        }
        setLoadingVisibility(false);
      });
  };

  const createNewUser = async () => {
    track('Creating new user', {
      user_id: user?.sub,
      email: user?.email,
      referrer,
      vula_claim_code,
      isAuthenticated,
      isLoading,
    });

    const auth0Token = await getAuth0Token();
    if (!auth0Token) {
      setErrorVisibility(true);
      return;
    }

    const api = new VulaUserAPI({ token: auth0Token });

    await api
      .postUserMe(user?.email || '', referrer, vula_claim_code)
      .then(() => {
        track('User creation success', {
          user_sub: user?.sub,
          referrer: referrer,
        });
        setLoadingVisibility(false);
        navigate(routes.private.onboarding);
      })
      .catch(e => {
        track('User creation error', {
          message: 'Failed to create new user',
          error: JSON.stringify(e),
          user_id: user?.sub,
        });
        setLoadingVisibility(false);
        setErrorVisibility(true);
        setError(e.message);
      });
  };

  if (showLoading) {
    return <Loading size="full-page" />;
  }

  if (showError) {
    track('Failed Login error', {
      user_id: user?.sub,
      error,
      user_email: user?.email,
    });
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <div className="max-w-[70%] m-auto max-h-[70%]">
          <div className="w-full flex justify-center rotate-[155deg] ">
            <VulaLogo />
          </div>
          <div className="text-4xl font-bold text-center py-12">Oops!</div>
          <div>
            Something went wrong. It looks like Vula is down right now. Sorry!
          </div>
          <br />
          <div className="text-xs">
            Error:
            <br />
            {error}
          </div>
          <br />
          <br />
          <div>
            This has been reported to our team and we will fix it as soon as we
            can. Please add your email here and we will reach out to get this
            fixed for you and help make you a superpowered fundraiser!
          </div>
          <SingleLineInput
            placeholder="Email"
            onSubmit={email => {
              track('Failed signup contact request', { email });
            }}
            buttonText="Report Error"
          />
        </div>
      </div>
    );
  }

  return <div>Redirecting...</div>;
};
