import React from 'react';
import VulaLogo from '../../utils/VulaLogo';
import { sendNotification } from '../../../api/monitoring';

export default function SomethingWrongPublicForm() {
  // on load send an email to Alex to fix the issue
  React.useEffect(() => {
    // send email using monitoring endpoint
    (async () => {
      // send email to Alex
      await sendNotification(
        'Error in public form at this url:' + window.location.href,
        'Public form error',
        'a',
      );
    })();
  }, []);

  return (
    <div className="p-12">
      <button
        className="w-full bg-white rounded-xl shadow"
        onClick={
          // navigate to the home page
          () => {
            window.location.href = '/';
          }
        }
      >
        <div className="flex justify-center items-center h-20 ">
          <div className="rotate-[175deg] ">
            <VulaLogo />
          </div>
        </div>
        <p className="text-2xl font-bold py-4">Something went wrong!</p>
      </button>
    </div>
  );
}
