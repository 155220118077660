import React, { useContext } from 'react';
import { ApplicationContextPublic } from '../../contexts/ApplicationContextPublic';
import {
  ApplicationContext,
  LendingProductApplicationQuestion,
  LendingProductApplicationSection,
} from '../../contexts/ApplicationContext';
import LendingApplicationQuestion from './LendingApplicationQuestion';
import { EditPitchContext } from '../../contexts/EditPitchContext';

interface Props {
  type: string;
}

export default function FlexibleSectionOfQuestions(props: Props) {
  // if we are on a public url use the public context, else use the private context
  const { formDefinition, upsertAnswer } = useContext(ApplicationContextPublic);
  const { lendingProductApplicationDefinition, updateAnswerInApplicationJson } =
    useContext(ApplicationContext);
  const { updateCorpus, updateCompanyDetails } = useContext(EditPitchContext);
  const [isPublicApplication, setIsPublicApplication] = React.useState(false);

  const [section, setSection] = React.useState<
    LendingProductApplicationSection | undefined
  >(undefined);

  React.useEffect(() => {
    // if it is a public url get the section from the public context,
    if (formDefinition) {
      setIsPublicApplication(true);
      const section = formDefinition.lendingProductApplicationSections?.filter(
        // todo this should be type matches type
        section => section.type === props.type,
      );
      if (section?.length) {
        setSection(section[0]);
      }
    } else {
      setIsPublicApplication(false);
      // else get the section from the private context
      const section =
        lendingProductApplicationDefinition?.LendingProductApplicationSection.filter(
          section => section.type === props.type,
        );
      if (section?.length) {
        setSection(section[0]);
      }
    }
  }, [formDefinition, lendingProductApplicationDefinition, props.type]);

  const saveAnswer = async (
    question: LendingProductApplicationQuestion,
    answer: string,
  ) => {
    // if it is a public url save the answer in the public context
    if (formDefinition) {
      return upsertAnswer({ ...question, answer });
    } else {
      // else save the answer in the private context
      updateAnswerInApplicationJson(question.id, answer);
      if (question.answer_source === 'corpus')
        return updateCorpus(question.label, answer);
      else if (question.answer_source === 'company_details')
        return updateCompanyDetails(question.label, answer);
    }
  };

  if (!section) return <>Loading...</>;

  return (
    <>
      <div>{section?.section_subtitle}</div>
      <div className="pt-2 sm:pt-4">
        {section?.section_questions.map(question => (
          <LendingApplicationQuestion
            key={question.id}
            question={question}
            saveAnswer={async answer => {
              await saveAnswer(question, answer);
            }}
            isPublicApplication={isPublicApplication}
          />
        ))}
      </div>
    </>
  );
}
