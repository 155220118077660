import React, { useContext, useEffect, useState } from 'react';
import VulaLogo from './VulaLogo';
import { copyToClipboard } from '../../methods/UIHelpers';

import PulsingOutlineWrapper from './PulsingOutlineWrapper';
import { useAuth0 } from '@auth0/auth0-react';
import { LoggedInContext } from '../../contexts/LoggedInContext';
import { apiUrl } from '../../methods/env';
import { VulaPitchAPI } from '../../api/pitch';
import { VulaCompanyAPI } from '../../api/company';
import CopyIcon from '../Icons/CopyIcon';
import { track } from '../../interfaces/mixpanel';
import Button from './Button';

interface Props {
  nextStepComponent: React.ReactNode;
  title: string;
  subtitle?: React.ReactNode;
  whoToEmail?: whoToEmail[];
}

interface whoToEmail {
  name: string;
  email: string;
}

export default function SharePitchStructure(props: Props) {
  const { getAccessTokenSilently } = useAuth0();
  const {
    company_slug,
    company_name,
    company_elevator_pitch,

    setCompanyElevatorPitch,
  } = useContext(LoggedInContext);
  // state
  const [passphrase, setPassphrase] = useState('');

  const pitchUrl = window.location.origin + '/' + company_slug;
  const [emailBody, setEmailBody] = useState('');

  // on load get pitch as it maybe out of date
  useEffect(() => {
    (async () => {
      await get_elevator_pitch();
      await getPitchPassword();
    })();
  }, []);

  // sometimes the company slug is not set on load
  useEffect(() => {
    (async () => {
      await get_elevator_pitch();
      await getPitchPassword();
    })();
  }, [company_slug]);

  const createEmailBody = (): string => {
    const elevator = company_elevator_pitch.length
      ? company_elevator_pitch
      : '';

    return `Hello ${
      props?.whoToEmail?.length ? props.whoToEmail[0].name : null
    },\n\n${elevator}\n\nLearn more about us and our ask here: \n${pitchUrl}\n\nThe password is:${passphrase} \n\nLook forward to chatting soon!`;
  };

  // on load of elevator pitch
  useEffect(() => {
    setEmailBody(createEmailBody());
  }, [company_elevator_pitch, company_slug, passphrase]);

  const get_elevator_pitch = async () => {
    const auth0Token = await getAccessTokenSilently({
      audience: apiUrl,
    });

    const pitchApi = new VulaPitchAPI({ token: auth0Token });
    try {
      const elevator_pitch = await pitchApi.getElevatorPitch(company_slug);

      setCompanyElevatorPitch(elevator_pitch.data.answer);
    } catch (error) {
      console.log(error);
    }
  };

  const updateElavatorPitch = async (elevator_pitch: string) => {
    if (elevator_pitch.length) {
      const auth0Token = await getAccessTokenSilently({
        audience: apiUrl,
      });
      const pitchApi = new VulaPitchAPI({ token: auth0Token });
      try {
        await pitchApi.updateElevatorPitch(company_slug, elevator_pitch);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getPitchPassword = async () => {
    const token = await getAccessTokenSilently();
    const api = new VulaCompanyAPI({ token });
    try {
      const res = await api.getCompanyPitchPassword(company_slug);
      setPassphrase(res.data.passphrase);
    } catch (error) {
      return;
    }
  };

  const getWhoToEmailAddresses = () => {
    // get all emails from whoToEmail
    const emails = props?.whoToEmail?.map(whoToEmail => whoToEmail.email);
    // join them with a semi-colon
    const emailsString = emails?.length ? emails.join(';') : '';
    return emailsString;
  };

  const prepareMailToEmail = () => {
    const emailsString = getWhoToEmailAddresses();

    const mailToEmail = `mailto:${emailsString}?subject=${company_name}&body=${encodeURI(
      emailBody,
    )}`;
    return mailToEmail;
  };

  const prepareGmailEmail = () => {
    const emailsString = getWhoToEmailAddresses();

    const mailToEmail = `https://mail.google.com/mail/?view=cm&to=${emailsString}&fs=1&&su=${company_name}&body=${encodeURI(
      emailBody,
    )}`;
    return mailToEmail;
  };

  return (
    <div className="max-w-[1000px] w-full min-h-[100vh] m-auto">
      <div className="  flex flex-row justify-center pt-12 ">
        <VulaLogo />
      </div>
      <div className="flex flex-col justify-center  text-center pt-4 sm:pt-12">
        <h1 className="text-2xl sm:text-5xl font-extrabold">{props.title}</h1>
      </div>

      <div className="pt-4">
        Share your pitch with an investor by sending them this link or an email
      </div>
      <div className="w-full flex justify-center pt-2">
        <Button
          text={'Copy ' + company_slug + "'s link"}
          action={() => {
            navigator.clipboard.writeText(
              window.location.origin + '/' + company_slug,
            );
          }}
        />
      </div>

      {props.subtitle ? props.subtitle : <div className="py-6"></div>}
      <div className="w-full max-w-[800px] m-auto text-left flex flex-row items-center text-gray-900 rounded-lg p-2">
        <div className="w-full rounded-xl bg-stone-100 relative ring ring-1 ring-solid ring-stone-200 flex flex-row items-center p-2 pt-16 sm:pt-2">
          <div className="absolute top-0 right-0 m-2">
            <button
              onClick={() => {
                track('onboarding: copy email', {
                  company_slug,
                });
                copyToClipboard(getWhoToEmailAddresses() + '\n\n' + emailBody);
              }}
              className="active:scale-[0.95] mx-2"
            >
              <div className="bg-stone-200 hover:bg-white flex flex-row ring ring-1 ring-solid ring-stone-200 p-2 rounded-full">
                <div className="px-2">Copy</div> <CopyIcon />
              </div>
            </button>
            <a href={prepareMailToEmail()}>
              <button
                onClick={() => {
                  track('onboarding: mailto', {
                    company_slug,
                  });
                  copyToClipboard(emailBody);
                }}
                className="active:scale-[0.95] mx-2"
              >
                <div className="bg-stone-200 hover:bg-white flex flex-row ring ring-1 ring-solid ring-stone-200 p-2 rounded-full">
                  <div className="px-2">
                    Email{' '}
                    {props?.whoToEmail?.length
                      ? props.whoToEmail[0].name
                      : null}{' '}
                    in email app
                  </div>
                </div>
              </button>
            </a>
            <button
              onClick={() => {
                track('onboarding: gmail', {
                  company_slug,
                });
                window.open(prepareGmailEmail(), '_blank');
              }}
              className="active:scale-[0.95] mx-2"
            >
              <div className="bg-stone-200 hover:bg-white flex flex-row items-center ring ring-1 ring-solid ring-stone-200 p-2 rounded-full">
                <div className="px-2">
                  Email{' '}
                  {props?.whoToEmail?.length ? props.whoToEmail[0].name : null}{' '}
                  in Gmail
                </div>
                <img
                  src="https://www.gstatic.com/images/branding/product/2x/gmail_48dp.png"
                  alt="Gmail Logo"
                  className="w-6 h-6 mx-2"
                />
              </div>
            </button>
          </div>
          <div className="p-2" style={{ whiteSpace: 'pre-line' }}>
            <div className="p-2">Hello,</div>
            <PulsingOutlineWrapper>
              <div
                className="text-left"
                contentEditable
                onBlur={async e =>
                  await updateElavatorPitch(e.target.innerText)
                }
              >
                {company_elevator_pitch.length ? company_elevator_pitch : ''}
              </div>
            </PulsingOutlineWrapper>

            <div className="p-2">
              Learn more about us and our ask here: <br />
              {pitchUrl}
              <br />
              <br />
              The password is: {passphrase}
              <br />
              <br />
              Look forward to chatting soon!
            </div>
          </div>
        </div>
      </div>
      {props?.nextStepComponent ?? null}
    </div>
  );
}
