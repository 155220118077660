import React from 'react';
import PublicFormHeader from './PublicFormHeader';
import { ApplicationContextPublic } from '../../../contexts/ApplicationContextPublic';
import ApplicationSectionQuestions from '../ApplicationSectionQuestions';
import SubmitApplicationPublic from './SubmitApplicationPublic';
import AlreadyHaveAVulaAccount from './AlreadyHaveAVulaAccount';

export default function PublicApplicationForm() {
  const { applicationStepName } = React.useContext(ApplicationContextPublic);

  return (
    <div className="py-2 p-1 shadow rounded-xl overflow-hidden bg-white w-full text-start h-auto transition-all duration-300">
      {/* Heading */}
      <PublicFormHeader />
      {applicationStepName === 'completed' ||
      applicationStepName === 'error-company-exists' ? null : (
        <>
          {/* aDD QUESTIONS */}
          <div className="px-2 sm:px-4">
            <ApplicationSectionQuestions
              hideSectionNavigation
              visibleTabState={{
                type: 'public-form',
                section_title: 'public-form',
              }}
            />
          </div>
          {/* submit button */}

          <SubmitApplicationPublic />
        </>
      )}

      {applicationStepName === '' ? (
        <>
          <div className="flex items-center w-full mt-4">
            <div className="w-full mx-4 border border-1 border-stone-100 border-solid rounded-xl " />
          </div>
          <AlreadyHaveAVulaAccount />{' '}
        </>
      ) : null}
    </div>
  );
}
